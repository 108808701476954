import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'

function buildColumnClasses(cols) {
  const [m, t, d] = cols
  return [
    `col-${m}_tp-${t}_ds-${d}`,
    `col-${m === 12 ? 12 : 12 - m}_tp-${t === 12 ? 12 : 12 - t}_ds-${
      d === 12 ? 12 : 12 - d
    }`,
  ]
}

const ImageDescriptionPanel = ({
  content_MD,
  colImg: { colWidth, alignment, src, alt, classes },
  inlineImages,
}) => {
  const [imgColClass, txtColClass] = buildColumnClasses(colWidth)

  return (
    <div
      className={`panel-image-description ${
        alignment === 'left' ? 'grid-reverse' : 'grid'
      }`}
    >
      <HTMLContent
        content={content_MD}
        className={`panel-image-description-text ${txtColClass}`}
        inlineImages={inlineImages}
      />
      <div className={`panel-image-description-image ${imgColClass}`}>
        <Img fluid={src.childImageSharp.fluid} alt={alt} className={classes} />
      </div>
    </div>
  )
}

ImageDescriptionPanel.propTypes = {
  content_MD: PropTypes.string,
  colImg: PropTypes.shape({
    colWidth: PropTypes.array.isRequired,
    alignment: PropTypes.string.isRequired,
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
    classes: PropTypes.string,
  }).isRequired,
  inlineImages: PropTypes.array,
}

export default ImageDescriptionPanel
